'use client';

import React from 'react';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { JucyMuiAccordion, JucyMuiAccordionDetails, JucyMuiAccordionSummary } from '@jucy-ui/components/JucyMuiAccordion';
import { UnstyledList } from '@/components/UnstyledList';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';
import { CmsLink } from '@/services/cms/models/CmsLink';
import { CmsMenu } from '@/services/cms/models/CmsMenu';

interface FooterMenuList {
    links: CmsLink[];
}

const FooterMenuList = ({ links }: FooterMenuList) => {
    const addSiteLinkPrefix = useSiteLinkPrefix();
    return (
        <UnstyledList>
            {links.map((link, i) => (
                <li key={`${link.id}-${i}`}>
                    <Link color="text.primary" target={link.openInNew ? '_blank' : undefined} href={addSiteLinkPrefix(link.url)} sx={{ py: 0.5, typography: 'caption' }}>
                        {link.title}
                    </Link>
                </li>
            ))}
        </UnstyledList>
    );
};

export interface FooterLinksProps extends BoxProps {
    menus: CmsMenu[];
    title?: string;
}

export const FooterLinks = ({ menus, title = '', ...props }: FooterLinksProps) => {
    const [activeGroup, setActiveGroup] = React.useState<string | null>(null);
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setActiveGroup(isExpanded ? panel : null);
    };
    return (
        <Box {...props}>
            <Typography variant="title1" component="div" sx={{ mb: 4 }}>
                {title}
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{ display: { xs: 'none', sm: 'flex' }}}
            >
                {menus?.map((menu) => {
                    if (!menu.links?.length) {
                        return null;
                    }
                    return (
                        <Grid
                            key={menu.title}
                            size={{ xs: 12, sm: 2 }}
                        >
                            <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
                                {menu.title}
                            </Typography>
                            <FooterMenuList links={menu.links} />
                        </Grid>
                    );
                })}
            </Grid>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                {menus?.map((menu) => (
                    <JucyMuiAccordion sx={{ background: 'none' }} key={menu.title} expanded={menu.title === activeGroup} onChange={handleChange(menu.title as string)}>
                        <JucyMuiAccordionSummary variant="chevron">
                            <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
                                {menu.title}
                            </Typography>
                        </JucyMuiAccordionSummary>
                        <JucyMuiAccordionDetails sx={(theme) => ({ p: { xs: theme.spacing(0, 2, 1, 2) } })}>
                            {menu.links?.length ? <FooterMenuList links={menu.links} /> : null}
                        </JucyMuiAccordionDetails>
                    </JucyMuiAccordion>
                ))}
            </Box>
        </Box>
    );
};
