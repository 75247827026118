'use client';

import { StackProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { mergeSx } from '@jucy-ui/appearance';
import { SocialLink } from '@/components/SocialLinkIcon';
import { CmsSocialNetwork } from '@/services/cms/models/CmsSocialNetwork';

export interface FooterSocialsProps extends StackProps {
    socials: CmsSocialNetwork[];
}

export const FooterSocials = ({ title, socials, direction = { xs: 'column', sm: 'row' }, gap = 3, sx, ...props }: FooterSocialsProps) => (
    <Stack
        direction={direction}
        {...props}
        sx={mergeSx({ alignItems: 'center', gap: gap  }, sx)}>
        <Typography variant="title1">{title}</Typography>
        <Stack
            direction={'row'}
            sx={{
                gap: gap,
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
            {socials.map((socialNetwork) => (
                <SocialLink key={socialNetwork.id} socialNetwork={socialNetwork} />
            ))}
        </Stack>
    </Stack>
);
