'use client';

import * as React from 'react';
import { ZodError } from 'zod';
import { ErrorPanel } from '@jucy-ui/components';
import { CmsHtmlContent } from '@/components/CmsHtmlContent';
import { SiteContainer } from '@/components/SiteContainer';
import { CmsSiteConfig } from '@/services/cms/models/CmsSiteConfig';
import { CmsErrorPageData } from '@/services/cms/pages/CmsErrorPageData';

export interface ErrorPageProps {
    siteConfig: CmsSiteConfig;
    children?: React.ReactNode;
    error?: Error;
    pageData?: CmsErrorPageData;
}

export const ErrorPage = (props: ErrorPageProps) => (
    <>
        <SiteContainer
            sx={(theme) => ({
                paddingTop: theme.settings?.appBar.spacer(),
            })}
        >
            {props.pageData ? (
                <SiteContainer sx={{ paddingBottom: 6, paddingTop: 4 }}>
                    <CmsHtmlContent html={props.pageData.content} />
                </SiteContainer>
            ) : null}
            {props.error ? <ErrorRender error={props.error} /> : null}
            {props.children}
        </SiteContainer>
    </>
);

const ErrorRender = ({ error }: { error: Error }) => {
    if (error instanceof ZodError) {
        return <pre>{JSON.stringify(error.format(), null, 4)}</pre>;
    }
    if (error.cause === 'not-found') {
        return (
            <>
                Page not found
                <br />
                {error.message}
            </>
        );
    }
    return <ErrorPanel title="Whoops">{error.message}</ErrorPanel>;
};
